import React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const HomeFeaturedListSectionUpdate = () => {
  const data = useStaticQuery(graphql`
    query HomeFeaturedListSectionUpdateQuery {
      prismicHomePageFeaturedThemesTemplates {
        data {
          wordpress_theme_name
          wordpress_theme_price
          wordpress_theme_link {
            url
          }
          wordpress_theme_image {
            alt
            gatsbyImageData(
              width: 600
              height: 293
            )
          }
          squarespace_template_name
          squarespace_template_price
          squarespace_template_link {
            url
          }
          squarespace_template_image {
            alt
            gatsbyImageData(
              width: 600
              height: 293
            )
          }
          shopify_theme_name
          shopify_theme_price
          shopify_theme_link {
            url
          }
          shopify_theme_image {
            alt
            gatsbyImageData(
              width: 600
              height: 293
            )
          }
        }
      }
    }
  `)

  const doc = data.prismicHomePageFeaturedThemesTemplates
  const wordPressThemeImg = getImage(doc.data.wordpress_theme_image)
  const squarespaceTemplateImg = getImage(doc.data.squarespace_template_image)
  const shopifyThemeImg = getImage(doc.data.shopify_theme_image)

  return (
    <div className="bg-gray-100 w-full px-4 pt-0 pb-0 md:pb-24">
      <div className="w-full flex flex-col items-center justify-center mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="w-full mb-12 md:mb-0">
            <a href={doc.data.wordpress_theme_link.url} target='_blank' rel='noopener noreferrer'>
              <GatsbyImage
                image={wordPressThemeImg}
                alt={wordPressThemeImg.alt}
                objectFit="fill"
                className="border border-gray-300 hover:border-gray-900"
              />
            </a>

            <p className="text-gray-700 text-xs font-medium uppercase mt-6">WordPress</p>

            <h2 className="text-gray-800 hover:text-gray-900 font-medium text-lg md:text-xl hover:underline mt-2"><a href={doc.data.wordpress_theme_link.url} target='_blank' rel='noopener noreferrer'>{doc.data.wordpress_theme_name}</a></h2>

            <p className="text-gray-600 text-md font-medium uppercase mt-2">{doc.data.wordpress_theme_price}</p>
          </div>

          <div className="w-full mb-12 md:mb-0">
            <a href={doc.data.squarespace_template_link.url} target='_blank' rel='noopener noreferrer'>
              <GatsbyImage
                image={squarespaceTemplateImg}
                alt={squarespaceTemplateImg.alt}
                objectFit="fill"
                className="border border-gray-300 hover:border-gray-900"
              />
            </a>

            <p className="text-gray-700 text-xs font-medium uppercase mt-6">Squarespace</p>

            <h2 className="text-gray-800 hover:text-gray-900 font-medium text-lg md:text-xl hover:underline mt-2"><a href={doc.data.squarespace_template_link.url} target='_blank' rel='noopener noreferrer'>{doc.data.squarespace_template_name}</a></h2>

            <p className="text-gray-600 text-md font-medium uppercase mt-2">{doc.data.squarespace_template_price}</p>
          </div>

          <div className="w-full mb-12 md:mb-0">
            <a href={doc.data.shopify_theme_link.url} target='_blank' rel='noopener noreferrer'>
              <GatsbyImage
                image={shopifyThemeImg}
                alt={shopifyThemeImg.alt}
                objectFit="fill"
                className="border border-gray-300 hover:border-gray-900"
              />
            </a>

            <p className="text-gray-700 text-xs font-medium uppercase mt-6">Shopify</p>

            <h2 className="text-gray-800 hover:text-gray-900 font-medium text-lg md:text-xl hover:underline mt-2"><a href={doc.data.shopify_theme_link.url} target='_blank' rel='noopener noreferrer'>{doc.data.shopify_theme_name}</a></h2>

            <p className="text-gray-600 text-md font-medium uppercase mt-2">{doc.data.shopify_theme_price}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeFeaturedListSectionUpdate